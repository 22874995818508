import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  getSite,
  getSitePages,
  getSiteSubs,
  getTheme,
} from 'services/CommonServices';
import SiteSubGenerator from 'comp/SiteSubGenerator';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css';
import SEOPanel from '../Common/SEOPanel';
import { ThemeProvider } from 'styled-components';

const App = (props) => {
  const [site, setSite] = useState({ hasBackground: 0 });
  const [pages, setPages] = useState(null);
  const [siteSubs, setSiteSubs] = useState(null);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const memberId = process.env.REACT_APP_MEMBERS_ID;

    getSite(memberId)
      .then((site) => {
        setSite(site.data.data);
        const themeId = site.data.data.themeId;
        return getTheme(themeId);
      })
      .then((response) => response.data.data)
      .then((data) => {
        setTheme({
          colors: data.colors,
          font: data.font,
        });
      })
      .then(() => {
        return getSitePages(memberId);
      })
      .then((result) => {
        const pages = result.data.data;
        const pageQueries = [];

        for (let i = 0; i < pages.length; i++) {
          pageQueries.push(getSiteSubs(pages[i].id));
        }

        return Promise.all(pageQueries).then((pageValues) => {
          const pagesResult = pageValues.map((value) => {
            return { pageId: value.data.pageId, data: value.data.data };
          });
          pages.map((page) => {
            const pageResult = pagesResult.find(
              (pageResult) => Number(pageResult.pageId) === Number(page.id),
            );
            page.siteSubs = pageResult.data || [];
          });

          setPages(pages);
        });
      })
      .catch((err) => {
        console.error(err);
      });

    AOS.init();

    // Clean up
    return () => {
      AOS.refresh();
    };
  }, []);

  const GlobalStyle = createGlobalStyle`
    body {
      background-color: #${(props) => props.theme.colors.bodyColor};
      font-family: ${(props) => props.theme.font.name}, sans-serif;
    }
  `;

  const SiteContainer = styled.div`
    transition: all 0.3s;
    ${site.hasBackground &&
    `      
      position: relative;
      overflow: hidden;
      background-color: #FFF;
      margin-top: 30px;
      margin-bottom: 30px;
      border-radius: 10px;
      box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.3);

      @media (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
      }
    `}
  `;

  return pages ? (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SiteContainer className={`App ${site.hasBackground && 'container'}`}>
        {pages.length > 1 ? (
          <div className="container">다중 페이지</div>
        ) : (
          <div>
            {pages.map((page) => {
              return [
                <SEOPanel site={site} page={page} />,
                <Helmet key="siteSEO">
                  <link href={theme.font.url} rel="stylesheet" />
                  {page.seo ? (
                    page.seo.map((seoElement) => {
                      if (seoElement.name === 'title')
                        return <title>{seoElement.content}</title>;
                      else if (seoElement.name === 'favicon')
                        return (
                          <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href={seoElement.content}
                          />
                        );
                      return (
                        <meta
                          name={seoElement.name}
                          content={seoElement.content}
                        />
                      );
                    })
                  ) : (
                    <title>Loading...</title>
                  )}
                </Helmet>,
                page.siteSubs ? (
                  page.siteSubs.map((siteSub) => (
                    <SiteSubGenerator site={site} siteSub={siteSub} />
                  ))
                ) : (
                  <div></div>
                ),
              ];
            })}
          </div>
        )}
      </SiteContainer>
    </ThemeProvider>
  ) : (
    <SiteContainer className="App">
      <header className="App-header">Loading...</header>
    </SiteContainer>
  );
};

export default App;
